<template>
  <div class="page-header" :style="{top: scaleValue.x * 140 + 'px'}">
    <div class="bread-cover">
      <bread :breads="breads"></bread>
    </div>
    <div class="tab-cover">
      <tabs :tabs="tabs" :active="activeTab" @change="setTab"></tabs>
    </div>
  </div>
</template>

<script>
import Bread from "./Bread.vue";
import Tabs from "./Tabs.vue";
export default {
  name: "page-header",
  data() {
    return {
      scaleValue: {
        x: 1,
        y: 1
      }
    }
  },
  components: {
    Bread,
    Tabs,
  },
  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    activeTab: [String],
    name: {
      type: String,
      default: "爱情地产",
    },
  },
  computed: {
    breads() {
      if (this.name === this.activeTab) {
        return [this.name];
      }
      return [this.name, this.activeTab];
    },
  },
  methods: {
    setTab(tab) {
      this.active = tab;
      this.$emit("tab-change", tab);
    },
  },
  mounted() {
    this.scaleValue = window.scaleValue || {
      x: 1,
      y: 1
    };
  },
};
</script>

<style lang="less" scoped>
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0 160px;
  // height: 70px;
  height: 100px;
  box-shadow: 0px 1px 0px 0px #cccccc;
  // position: sticky;
  // top: 140px;
  // top: 80px;
  z-index: 9;
  
  .bread-cover,
  .tab-cover {
    height: 100%;
  }
}
</style>
