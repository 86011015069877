<template>
  <div class="tabs">
    <div
      class="tab-item"
      :class="active === tab ? 'active' : ''"
      v-for="(tab, idx) in tabs"
      :key="`${idx}`"
      @click="setTab(tab)"
    >
      <span>{{ tab }}</span>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabs",
  data() {
    return {
      metas: [],
    };
  },
  props: {
    active: [String],
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    setTab(tab) {
      if (tab !== this.active) {
        let idx = this.metas.findIndex((i) => i.name === tab);
        if (idx > -1) {
          this.$router.push(this.metas[idx].url);
        } else {
          this.$emit("change", tab);
        }
      }
    },
    getMetasByRouter() {
      this.metas = this.getMeta(this.$router.options.routes);
    },
    getMeta(arr) {
      if (!arr || !arr.length || arr.length < 0) return;
      let metas = [];
      for (let i = 0; i < arr.length; i++) {
        const route = arr[i];
        const meta = route.meta;
        if (meta && meta.name && meta.url) {
          metas.push(meta);
        }

        if (route.children && route.children.length > 0) {
          metas = metas.concat(this.getMeta(route.children));
        }
      }
      return metas;
    },
  },
  created() {
    this.getMetasByRouter();
  },
};
</script>

<style lang="less" scoped>
.tabs {
  display: flex;
  align-items: center;
  height: 100%;
  .tab-item {
    display: flex;
    align-items: center;
    // font-size: 12px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    position: relative;
    height: 100%;
    margin-left: 30px;
    .line {
      width: 0;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -15px;
      height: 4px;
      transition: 0.3s all;
      background: linear-gradient(135deg, #f68e0e 0%, #e95506 100%);
    }
    &.active {
      color: #e95506;
      font-weight: bold;
      transition: 0.3s all;
      .line {
        width: 30px;
      }
    }
    a{
      color: #000;
      text-decoration: none;
    }
  }
}
</style>
