<template>
  <div class="bread">
    <img src="../../assets/imgs/home-icon.png" alt="" class="home" />
    <div class="bread-item" v-for="(bread, idx) in breads" :key="`bread-${idx}`">
      <img src="../../assets/imgs/next-icon.png" alt="" class="next-icon" />
      <span>{{ bread }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "bread",
  props: {
    breads: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style lang="less" scoped>
.bread {
  display: flex;
  align-items: center;
  height: 100%;
  .home {
    // width: 16px;
    // height: 16px;
    width: 24px;
    height: 24px;
  }
  .bread-item {
    // margin-left: 8px;
    margin-left: 23px;
    .next-icon {
      // width: 8px;
      // height: 8px;
      width: 12px;
      height: 12px;
      // margin-right: 8px;
      margin-right: 23px;
    }
    span {
      // font-size: 12px;
      font-size: 20px;
      line-height: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
}
</style>
