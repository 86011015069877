<template>
  <div class="news">
    <div class="img-header">
      <img src="../../assets/images/news-header.png" alt="" />
    </div>
    <page-header
      :tabs="tabs"
      name="新闻中心"
      :active-tab="activeTab"
      @tab-change="tabChange"
    ></page-header>

    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import PageHeader from "../../components/PageHeader/PageHeader.vue";

export default {
  data() {
    return {
      tabs: ["集团要闻", "媒体报道", "品牌影像", 
      // "品牌画册",
      "社交媒体"],
      activeTab: "集团要闻",
    };
  },
  components: {
    PageHeader,
  },
  watch: {
    $route: {
      immediate: true,
      handler(v) {
        if (v && v.meta.name) {
          this.activeTab = v.meta.name;
        }
      },
    },
  },
  methods: {
    tabChange(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped lang="less">
.news {
  width: 100%;
  height: 100%;
  padding-top: 140px;
  .img-header {
    width: 100%;
    height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
